@use "sass:math";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/mixins/rtl";

.color-picker {
  &__input {
    display: flex;
    margin-bottom: 8px;

    @media (max-width: $screen-xs-max) {
      margin-bottom: 16px;
    }

    &__sample {
      width: 40px;
      height: 40px;

      border-radius: 3px;
      @include side-spacing(margin, right, 8px);
    }

    input {
      width: 130px;
      padding: 10px 8px;
      border: 1px solid $geyser;
      border-radius: 3px;

      &:hover {
        border-color: $gray-chateau;
      }

      &:focus,
      &:active {
        border-color: var(--color-primary);
        outline: 0;
      }
    }
  }

  &__sliders {
    position: relative;
    z-index: 1;

    &__hue {
      position: absolute;
      top: 0;
      width: 20px;
      height: 150px;
      cursor: row-resize;
      @include side-position(left, 158px);
    }

    .hue-vertical > div {
      right: 0;
    }

    @media (max-width: $screen-xs-max) {
      height: 24px;

      &__hue {
        width: 100%;
        height: 100%;
        cursor: col-resize;

        @include side-position(left, 0);
      }
    }

    &__saturation {
      position: relative;
      top: 0;
      height: 150px;
      width: 150px;
    }
  }
}

$circle-pointer-inner-radius: 8px;
$circle-pointer-outer-radius: $circle-pointer-inner-radius + 8px;

.circle-pointer {
  position: absolute;
  top: math.div($circle-pointer-outer-radius, -2);
  width: $circle-pointer-inner-radius;
  height: $circle-pointer-inner-radius;
  border-radius: $circle-pointer-inner-radius;
  border: solid 3px $white;
  box-sizing: content-box;
  z-index: 1;
  @include side-position(left, math.div($circle-pointer-outer-radius, -2));
}

.circle-pointer:before {
  width: $circle-pointer-outer-radius;
  height: $circle-pointer-outer-radius;
  border-radius: $circle-pointer-outer-radius;
  content: " ";
  position: absolute;
  top: -4px;
  border: 1px solid $black;
  @include side-position(left, -4px);
}

.bar-pointer--vertical,
.bar-pointer--horizontal {
  position: absolute;
  background: $white;
  border: solid 1px $black;
}

.bar-pointer--vertical {
  top: -2px;
  width: 20px;
  height: 4px;
}

.bar-pointer--horizontal {
  left: -5px;
  width: 10px;
  height: 24px;
  border-radius: 1px;
}
